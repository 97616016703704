import { useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import ReplenishmentTable from "components/ReplenishmentTable";
import CustomInput from "components/Input";
import CustomButton from "components/Button";
import useFetch from "hooks/useFetch";
import TelegramIcon from "assets/icons/TelegramIcon";
import ToastMessage from "components/ToastMessage";
import { getToken } from "utils/getToken";
import { PaymentStatusModal } from "components/PaymentStatusModal";

const buttonDollar = {
  padding: "0px 16px",
  background: "#F6F7FB",
  borderRadius: "8px",
  color: "#000",
  fontFamily: "var(--cygreRegular), sans-serif",
  fontSize: "16px",
  border: "2px solid transparent",
};

const selectedButtonStyle = {
  background: "rgba(244, 112, 27, 0.08)",
  border: "2px solid #F4701B",
};

function PaymentPage() {
  const { t, i18n } = useTranslation();
  const [selectedAmount, setSelectedAmount] = useState<string>("");
  const [bonus, setBonus] = useState<string>("");
  const token = getToken();
  const handleButtonClick = (amount: string) => {
    setSelectedAmount(amount);
  };

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSelectedAmount(value);
  };

  const handleBonusChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setBonus(value);
  };

  const { triggerFetch } = useFetch({
    url: "invoice",
    method: "POST",
    body: { amount: parseFloat(selectedAmount), lang: i18n.language },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const handleReplenishment = async () => {
    const res = await triggerFetch();

    if (res.success) {
      window.open(`${res.data.url}`, "_blank");
    } else {
      const message = res.message;
      ToastMessage({ mode: "error", message });
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
        width: "100%",
        flexDirection: { xs: "column", lg: "row" },
      }}
    >
      <PaymentStatusModal />
      <Box sx={{ width: { xs: "100%", lg: "48%" }, border: "1px solid #EEEEEE", borderRadius: "8px", padding: "36px" }}>
        <Typography variant="h5">{t("replenishmentofbalance")}</Typography>
        <Box sx={{ display: "flex", gap: 2, marginTop: 2 }}>
          {["25", "30", "35", "40"].map((amount: string) => (
            <Button
              key={amount}
              sx={{
                ...buttonDollar,
                ...(selectedAmount === amount && selectedButtonStyle),
              }}
              onClick={() => handleButtonClick(amount)}
            >
              ${amount}
            </Button>
          ))}
        </Box>
        <Box>
          <Box
            sx={{
              paddingTop: "24px",
            }}
          >
            <Typography variant="body2" sx={{ fontFamily: "var(--cygreMedium), sans-serif" }}>
              {t("replenishmentamount")}
            </Typography>
            <CustomInput
              margin="normal"
              required
              fullWidth
              id="replenishmentamount"
              height="40px"
              placeholder={t("enteramount")}
              name="replenishmentamount"
              value={selectedAmount}
              onChange={handleAmountChange}
              customBgColor="#F6F7F9"
              iconStart={"$"}
            />
            <Typography
              sx={{
                textAlign: "left",
                fontSize: "0.75em",
                color: "black",
                opacity: "0.3",
                fontFamily: "var(--cygreBold)",
                marginTop: "-10px",
                marginLeft: "12px",
              }}
            >
              {t("minimumamount")} $25
            </Typography>
          </Box>
        </Box>
        <Box sx={{ padding: "24px 0 56px" }}>
          <Typography variant="body2" sx={{ fontFamily: "var(--cygreMedium), sans-serif" }}>
            {t("bonus")}
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Box sx={{ width: "100%" }}>
              <CustomInput
                margin="normal"
                required
                fullWidth
                id="bonus"
                height="40px"
                placeholder={t("enterpromo")}
                name="bonus"
                value={bonus}
                onChange={handleBonusChange}
                customBgColor="#F6F7F9"
              />
            </Box>
            <Box
              component="button"
              sx={{
                marginTop: "9px",
                padding: "10px",
                borderRadius: "8px",
                background: bonus ? "#F4701B" : "#F6F6F6",
                border: 0,
                cursor: bonus ? "pointer" : "not-allowed",
                pointerEvents: bonus ? "auto" : "none",
                outline: 0,
                width: 40,
                height: 40,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginLeft: "15px",
              }}
              disabled={!bonus}
            >
              <TelegramIcon color={bonus ? "#fff" : "#D1D1D1"} />
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", flexDirection: "column" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between", marginBottom: "24px" }}>
            <Typography variant="h6">{t("amount")}</Typography>
            {selectedAmount && <Typography variant="h6">${selectedAmount}</Typography>}
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between", marginBottom: "24px" }}>
            <Typography variant="h6">{t("bonus")}</Typography>
            {bonus && <Typography variant="h6">$0</Typography>}
          </Box>
          <Box>
            <CustomButton
              width={"100%"}
              textButton={t("replenish")}
              height={40}
              customBgColor={"#F4701B"}
              customHoverColor={"#F4701B"}
              customBorderRadius="8px"
              customFontSize="14px"
              onClick={handleReplenishment}
              disabled={!selectedAmount}
            />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          width: { xs: "100%", lg: "48%" },
          marginTop: { xs: "20px", lg: "0" },
          border: "1px solid #EEEEEE",
          borderRadius: "8px",
          padding: "36px",
        }}
      >
        <Typography sx={{ fontFamily: "var(--cygreMedium), sans-serif", paddingBottom: "24px" }} variant="h6">
          {t("historyreplenishment")}
        </Typography>
        <ReplenishmentTable />
      </Box>
    </Box>
  );
}

export default PaymentPage;
