import { useState, useCallback } from "react";

interface UseFetchProps {
  url: string;
  method?: string;
  headers?: HeadersInit;
  body?: any;
  context?: {
    no401Redirect?: boolean;
  };
}

function useFetch({ url, method = "GET", headers, body, context }: UseFetchProps) {
  const [data, setData] = useState(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const triggerFetch = useCallback(
    async (overrideBody?: any, customUrl?: string) => {
      setLoading(true);
      setError(null);

      const options: RequestInit = {
        method,
        headers: {
          "Content-Type": "application/json",
          ...headers,
        },
      };

      const finalBody = overrideBody ? { ...body, ...overrideBody } : body;

      if (finalBody) {
        options.body = JSON.stringify(finalBody);
      }

      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}${customUrl ? customUrl : url}`, options);

        const result = await response.json();
        if (response.status === 401) {
          if (context?.no401Redirect) {
            return result;
          }
          window.location.assign("/");
          return null;
        }
        setData(result);
        return result;
      } catch (err: unknown) {
        if (err instanceof Error) {
          setError(err.message);
        }
        return null;
      } finally {
        setLoading(false);
      }
    },
    [url, method, headers, body]
  );

  return { data, error, loading, triggerFetch };
}

export default useFetch;
