import React, { ChangeEvent, useEffect, useState } from "react";
import { Accordion, AccordionSummary, AccordionDetails, Typography, Box } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";
import CustomInput from "components/Input";
import { usePushStore } from "store/pushStore";
import { IPushTexts } from "interfaces/mockupApp";

export default function NewsletterContent({ selectedPwa }: any) {
  const { t } = useTranslation();
  const savePushData = usePushStore((state) => state.savePushData);

  const [textHeading, setTextHeading] = useState<string>("");
  const [textContent, setTextContent] = useState<string>("");

  const language: string = selectedPwa?.language || "";
  const normalizedLanguage = language === "" ? "en" : language;

  const handleHeadingChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = event.target;
    setTextHeading(value);

    const headings: IPushTexts = { en: value };

    if (normalizedLanguage !== "en") {
      headings[normalizedLanguage] = value;
    }

    savePushData({ headings });
  };

  const handleContentChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = event.target;
    setTextContent(value);

    const contents: IPushTexts = { en: value };

    if (normalizedLanguage !== "en") {
      contents[normalizedLanguage] = value;
    }

    savePushData({ contents });
  };

  return (
    <Accordion
      defaultExpanded
      sx={{
        boxShadow: "none",
        border: "1px solid #EEEEEE",
        borderRadius: "8px",
        background: "none",
        "&::before": {
          content: "none",
        },
      }}
    >
      <AccordionSummary sx={{ height: "48px" }} expandIcon={<ExpandMoreIcon />}>
        <Typography
          sx={{ lineHeight: "32px", fontSize: "16px", color: "#2E263D", fontFamily: "var(--cygreMedium), sans-serif" }}
        >
          {t("campaignContent")}
        </Typography>
      </AccordionSummary>
      <Box sx={{ maxWidth: "480px" }}>
        <AccordionDetails>
          <Box sx={{ paddingBottom: "24px" }}>
            <CustomInput
              margin="normal"
              required
              fullWidth
              id="author"
              height="40px"
              placeholder={t("enterHeadline")}
              name="author"
              label={t("headline")}
              value={textHeading}
              onChange={handleHeadingChange}
              customBgColor="#F6F7F9"
            />
          </Box>

          <Box sx={{ paddingBottom: "24px" }}>
            <CustomInput
              margin="normal"
              required
              fullWidth
              id="descriptionPC"
              placeholder={t("enterPushText")}
              multiline={true}
              rows={4}
              name="descriptionPC"
              label={t("text")}
              value={textContent}
              onChange={handleContentChange}
              customBgColor="#F6F7F9"
            />
          </Box>

          {/*
          TODO: return later
          <Box sx={{ paddingBottom: "24px" }}>
            <Box
              sx={{
                width: "100%",
                height: "200px",
                border: "1px dashed #C9D0D8",
                backgroundColor: "#F6F7FB",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                position: "relative",
              }}
            >
              <input
                type="file"
                multiple
                id="preview-upload"
                name="preview-upload"
                onChange={(e) => {
                  // console.log("image", e);
                }}
                style={{
                  opacity: 0,
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  cursor: "pointer",
                }}
              />
              <Typography
                sx={{
                  margin: "auto",
                  color: "#2E263D",
                  textAlign: "center",
                  fontSize: "14px",
                  lineHeight: "19px",
                  fontFamily: "CygreRegular",
                }}
              >
                {t("image")}
              </Typography>
            </Box>
          </Box> */}
        </AccordionDetails>
      </Box>
    </Accordion>
  );
}
