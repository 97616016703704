import * as React from "react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { SelectChangeEvent } from "@mui/material/Select";
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";

interface RangeOption {
  value: string;
  label: string;
}

interface RangeSelectComponentProps {
  options: RangeOption[];
  value: string;
  onChange: (event: SelectChangeEvent<string>) => void;
  noBorder?: boolean;
}

const CustomFormControl = styled(FormControl)<{ noBorder?: boolean }>(({ theme, noBorder }) => ({
  "& .MuiOutlinedInput-root": {
    height: 40,
    borderRadius: "8px",
    borderColor: noBorder ? "transparent" : undefined,
    "& fieldset": {
      borderColor: noBorder ? "transparent" : undefined,
    },
    "&:hover fieldset": {
      borderColor: noBorder ? "transparent" : "#F4701B",
    },
    "&.Mui-focused fieldset": {
      borderColor: noBorder ? "transparent" : "#F4701B",
    },
  },
}));

const RangeSelectComponent: React.FC<RangeSelectComponentProps> = ({ options, value, onChange, noBorder = false }) => {
  return (
    <Box sx={{ minWidth: 200, marginLeft: "20px" }}>
      <CustomFormControl fullWidth noBorder={noBorder}>
        <Typography sx={{ paddingBottom: "4px", fontSize: 12, fontWeight: "400" }}>Select Range</Typography>
        <Select value={value} onChange={onChange} displayEmpty sx={{ height: 40 }}>
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </CustomFormControl>
    </Box>
  );
};

export default RangeSelectComponent;
