import { AppDataMockup } from "interfaces/mockupApp";
import { useAppStore } from "store/appStore";
// import useIdStore from "store/pwaId";
// import useSetupStore from "store/setupSettingsStore";

export function getLocalStorageData(): Partial<AppDataMockup> {
  if (typeof window !== "undefined") {
    const storedData = localStorage.getItem("pwaData");

    try {
      return storedData && storedData !== "undefined" ? JSON.parse(storedData) : {};
    } catch (error) {
      console.error("Ошибка при парсинге данных из localStorage:", error);
      return {};
    }
  }

  return {};
}

export function getUpdatedFields(): Partial<AppDataMockup> | false {
  const { appData } = useAppStore.getState();
  const storedData = getLocalStorageData();
  if (!storedData) {
    return false;
  }

  const updatedFields: Partial<AppDataMockup> = {};

  Object.entries(appData).forEach(([key, value]) => {
    if (key === "rating") {
      // Преобразуем оба значения в числа и сравниваем
      if (Number(value) !== Number(storedData[key as keyof AppDataMockup])) {
        updatedFields[key as keyof AppDataMockup] = value;
      }
    } else if (
      key !== "translations" &&
      key !== "blackLink" &&
      storedData.hasOwnProperty(key) &&
      JSON.stringify(value) !== JSON.stringify(storedData[key as keyof AppDataMockup])
    ) {
      updatedFields[key as keyof AppDataMockup] = value;
    }
  });

  return Object.keys(updatedFields).length > 0 ? updatedFields : false;
}

// export function goBackPwa() {
//   const setSettingProcess = useSetupStore((state: any) => state.setSettingProcess);
//   const resetSteps = useSetupStore((state: any) => state.resetAllSteps);
//   const { clearAppData } = useAppStore();
//   const { id, clearId } = useIdStore();
//
//   clearAppData();
//   setSettingProcess(false);
//   resetSteps();
//   clearId();
//   localStorage.removeItem("pwaData");
// }

export {};

