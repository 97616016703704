import { useTranslation } from "react-i18next";

export default function SupportPage() {
  const { t } = useTranslation();
  return (
    <div>
      <h1>{t("supportpageindevelopment")}</h1>
    </div>
  );
}
