import React from "react";

// import { usePathname } from "next/navigation";
import { Box, List } from "@mui/material";
import NavItem from "./NavItem";
import NavGroup from "./NavGroup/NavGroup";
import useMenuItems from "./MenuItems";
import useSetupStore from "store/setupSettingsStore";
import useIdStore from "store/pwaId";
import { useAppStore } from "store/appStore";

const SidebarItems = ({ toggleMobileSidebar }: any) => {
  // const pathname = usePathname();
  const menuItems = useMenuItems();
  const { clearId } = useIdStore();
  const { clearAppData } = useAppStore();
  const pathDirect = "pathname";
  const setSettingProcess = useSetupStore((state: any) => state.setSettingProcess);
  const resetSteps = useSetupStore((state: any) => state.resetAllSteps);
  const toggleModal = () => {
    setSettingProcess(false);
    resetSteps();
    clearId();
    // clearAppData();
    // localStorage.removeItem("pwaData");
    // console.log(toggleMobileSidebar);
    // toggleMobileSidebar;
  };
  return (
    <Box sx={{ px: 3 }}>
      <List sx={{ pt: 0 }} className="sidebarNav" component="div">
        {menuItems.map((item: any) => {
          // {/********SubHeader**********/}
          if (item.subheader) {
            return <NavGroup item={item} key={item.subheader} />;

            // {/********If Sub Menu**********/}
            /* eslint no-else-return: "off" */
          } else {
            return <NavItem item={item} key={item.id} pathDirect={pathDirect} onClick={toggleModal} />;
          }
        })}
      </List>
    </Box>
  );
};
export default SidebarItems;
