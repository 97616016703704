import { Grid } from "@mui/material";
import NotificationCard from "./NotificationCard";
import { getCountryFlags } from "utils";
import { IAppData, INotification, INotificationsListProps } from "interfaces/push";
import { IPwa } from "interfaces/pwas";
import ToastMessage from "components/ToastMessage";
import { usePushStore } from "store/pushStore";
import { useEffect, useState } from "react";
import useFetch from "hooks/useFetch";

import { getToken } from "utils/getToken";
import { useTranslation } from "react-i18next";

const NotificationsList = ({ notifications, appData, pwas, countries, getNot }: INotificationsListProps) => {
  const matchedPwa = pwas?.find((pwa: IPwa) => pwa.osId === appData.id);
  const geo = matchedPwa?.geo || [];
  const { t } = useTranslation();
  const countryFlags = getCountryFlags(geo, countries);
  const lang = matchedPwa?.language || "en";
  const icon = matchedPwa?.creatives?.icon || null;
  const [appId, setAppId] = useState("");
  const [basicAuthKey, setBasicAuthKey] = useState("");
  const [notificationId, setNotificationId] = useState("");
  const { savePushData } = usePushStore();

  const token = getToken();

  const { triggerFetch: deleteMessage, loading } = useFetch({
    url: "push/onesignal-proxy-dynamic",
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: {
      method: "DELETE",
      uri: `/notifications/${notificationId}?app_id=${appId}`,
      bearer: `${basicAuthKey}`,
      requestBody: {},
    },
  });

  async function stopPush() {
    try {
      const res = await deleteMessage();
      if (res.success) {
        ToastMessage({ mode: "info", message: t("stopedPush") });
        await getNot(); // Обновляем пуши после успешного удаления
      } else {
        ToastMessage({ mode: "error", message: t("errorStoppingPush") });
      }
    } catch (error) {
      ToastMessage({ mode: "error", message: t("errorStoppingPush") });
    }
  }

  useEffect(() => {
    if (appId && notificationId && basicAuthKey) {
      stopPush();
    }
  }, [appId, notificationId, basicAuthKey]); // Убрал комментарий по зависимостям, т.к. это важно для запуска удаления

  const handleCardClick = (notification: INotification) => {
    savePushData({
      language: lang,
      icon: icon,
      headings: notification.headings,
      contents: notification.contents,
      completedAt: notification.completed_at,
    });
  };

  const handleDeleteClick = (appData: IAppData, notification: INotification) => {
    setAppId(appData.id);
    setNotificationId(notification.id);
    setBasicAuthKey(appData.basic_auth_key);
  };

  return (
    <>
      {notifications?.map((notification) => (
        <Grid item xs={12} lg={12 / 2} xl={12 / 3} key={notification.id}>
          <NotificationCard
            notification={notification}
            countryFlags={countryFlags}
            appData={appData}
            icon={icon}
            lang={lang}
            onCardClick={handleCardClick}
            onDeletePush={handleDeleteClick}
          />
        </Grid>
      ))}
    </>
  );
};

export default NotificationsList;
