import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useAppStore } from "store/appStore";
import useSetupStore from "store/setupSettingsStore";
import { getLanguages, translateText } from "utils/translationService";
import { textsToTranslate } from "utils/en";
import MainTab from "components/PWATabs/mainTab/MainTab";
import CreativesTab from "components/PWATabs/CreativesTab/CreativesTab";
import DomainTab from "components/PWATabs/domainTab/DomainTab";
import TrackingTab from "components/PWATabs/trackingTab/TrackingTab";
import NameApp from "components/PWATabs/mainTab/NameApp";
import PwaTabs from "components/PWATabs/Tabs";
import PhoneMockup from "components/PhoneMockup";
import PhoneMockupModal from "components/PhoneMockupModal";
import Eye from "assets/icons/Eye";
import PhoneContentPwa from "components/PWATemplate/PhoneContentPwa";
import useRouteChange from "hooks/useRouteChange";

function PWACreatePage() {
  const [languages, setLanguages] = useState(null);
  const { appData, clearAppData, saveAppData } = useAppStore();
  const [openModal, setOpenModal] = useState(false);
  const lastTabValue = localStorage.getItem("lastTabValue") || "0";
  const [value, setValue] = useState(Number(lastTabValue));
  const setStepStatus = useSetupStore((state) => state.setStepStatus);

  useRouteChange(() => {
    clearAppData();
    localStorage.removeItem("lastTabValue");
  });

  useEffect(() => {
    const stepNames = ["main", "creatives", "domain", "tracking"];
    const currentStep: any = stepNames[value];
    setStepStatus(currentStep, "inprogress");
  }, [value, setStepStatus]);

  useEffect(() => {
    clearAppData();
    const lang = async () => {
      const fetchLanguages: any = await getLanguages();
      setLanguages(fetchLanguages?.languages);
    };
    if (!languages) {
      lang();
    }
  }, []);

  useEffect(() => {
    const translate = async () => {
      if (appData.language === "en" || appData.language === "en-GB" || appData.language === "EN") {
        saveAppData({ translations: textsToTranslate });
      } else {
        const translatedValues = await translateText(textsToTranslate, appData.language);
        saveAppData({ translations: translatedValues });
      }
    };
    if (appData.language) {
      void translate();
    }
  }, [appData.language]);

  const renderTabContent = () => {
    switch (value) {
      case 0:
        return <MainTab setValue={setValue} appData={appData} languages={languages} />;
      case 1:
        return <CreativesTab setValue={setValue} />;
      case 2:
        return <DomainTab setValue={setValue} />;
      case 3:
        return <TrackingTab />;
      default:
        return null;
    }
  };

  return (
    <>
      <Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start" }}>
          <NameApp />
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ width: "100%" }}>
            <PwaTabs setValue={setValue} renderTabContent={renderTabContent} value={value} />
          </Box>

          {value !== 2 && (
            <>
              <Box
                sx={{
                  display: { xs: "none", xl: "block" },
                  marginTop: "71px",
                  marginLeft: "26px",
                  height: "fit-content",
                  position: "sticky",
                  top: "10%",
                  border: "1px solid #EEEEEE",
                  borderRadius: "8px",
                  padding: "50px 24px",
                }}
              >
                <PhoneMockup>
                  <PhoneContentPwa />
                </PhoneMockup>
              </Box>
              <Box
                onClick={() => setOpenModal(true)}
                sx={{
                  display: { xs: "flex", xl: "none" },
                  width: 40,
                  height: 40,
                  borderBottomLeftRadius: "8px",
                  borderTopLeftRadius: "8px",
                  background: "#F4701B",
                  marginTop: "71px",
                  marginLeft: { xs: "-24px", md: "24px" },
                  marginRight: "-24px",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                  position: "sticky",
                  top: "10px",
                }}
              >
                <Eye color={"#fff"} />
              </Box>
            </>
          )}
        </Box>
      </Box>

      <PhoneMockupModal openModal={openModal} setOpenModal={setOpenModal} />
    </>
  );
}
export default PWACreatePage;
