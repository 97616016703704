import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import moment from "moment";
import CustomTable, { IColumn } from "components/CustomTable";
import useFetch from "hooks/useFetch";
import Loader from "components/Loader";
import { getToken } from "utils/getToken";
import Preloader from "components/GlobalPreloader/Preloader";

interface IReplenishment {
  updatedAt: string;
  amount: string;
}

const ReplenishmentTable = () => {
  const token = getToken();
  const { t } = useTranslation();
  const [replenishment, setReplenishment] = useState<IReplenishment[]>([]);
  const [isFetched, setIsFetched] = useState(true);

  const columns: IColumn[] = [
    {
      id: "dateofentry",
      label: t("dateofentry"),
      align: "left",
    },
    {
      id: "amount",
      label: t("amount"),
      align: "right",
    },
  ];

  useEffect(() => {
    void getReplenishment();
  }, []);

  const { triggerFetch } = useFetch({
    url: "invoice",
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  async function getReplenishment() {
    const res = await triggerFetch();
    if (res && res.data && res.data.items) {
      setReplenishment(res?.data?.items || []);
    }
    setIsFetched(false);
  }

  const rows = replenishment
    .filter((item: any) => item.status === "paid")
    .map((item) => ({
      dateofentry: moment(item.updatedAt).format("DD.MM.YYYY HH:mm:ss"),
      amount: `$${item.amount}`,
    }));

  if (isFetched) {
    return <Preloader />;
  }

  if (replenishment.length === 0) {
    return <Typography variant="h6">{t("noreplenishments")}</Typography>;
  }

  return <CustomTable enablePagination={true} columns={columns} rows={rows} />;
};

export default ReplenishmentTable;
