import axios from "axios";
import { textsToTranslate } from "utils/en";
import { getToken } from "utils/getToken";

interface keyVal {
  [key: string]: string | number;
}

const getCachedTranslations = (texts: keyVal, targetLanguage: string) => {
  const cachedTranslations = JSON.parse(localStorage.getItem("translations") ?? "{}") || textsToTranslate;
  const translations: any = {};
  let allCached = true;
  // for (const key in texts) {
  Object.keys(texts).forEach((key) => {
    const cached =
      cachedTranslations[key] && cachedTranslations[key][targetLanguage]
        ? cachedTranslations[key][targetLanguage]
        : null;
    translations[key] = cached || texts[key];
    if (!cached) allCached = false;
  });

  return { translations, allCached };
};

const cacheTranslations = (translations: keyVal, targetLanguage: string) => {
  const cachedTranslations = JSON.parse(localStorage.getItem("translations") || "{}") || textsToTranslate;

  // for (const key in translations) {
  Object.keys(translations).forEach((key) => {
    if (!cachedTranslations[key]) cachedTranslations[key] = {};
    cachedTranslations[key][targetLanguage] = translations[key];
  });

  localStorage.setItem("translations", JSON.stringify(cachedTranslations));
};

export const translateText = async (texts: any, targetLanguage = "en-GB") => {
  const { translations, allCached } = getCachedTranslations(texts, targetLanguage);

  if (allCached) {
    return translations;
  }

  const translationsArray = Object.values(translations);

  const textsToTranslate = Object.values(texts).filter((text, key) => {
    return translationsArray[key as keyof typeof translationsArray] === text;
  });

  try {
    const token = getToken();
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}deepl/translate`,
      {
        text: textsToTranslate.join("\n"),
        target_lang: targetLanguage,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const translatedTexts = response.data.text?.split("\n");

    Object.keys(texts).forEach((key, index) => {
      if (translationsArray[index] === texts[key]) {
        translations[key] = translatedTexts.shift();
      }
    });

    cacheTranslations(translations, targetLanguage);

    return translations;
  } catch (error) {
    console.error("Translation API error:", error);
    return texts;
  }
};

export const getLanguages = async () => {
  try {
    const token = getToken();
    const response = await axios.get(`${process.env.REACT_APP_API_URL}deepl/languages`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response?.data || { languages: [] };
  } catch (error) {
    console.error("Translation API error:", error);
    return error;
  }
};
