"use client";
import { Grid, Box, Button, SelectChangeEvent } from "@mui/material";
import useFetch from "hooks/useFetch";
import { useEffect, useState } from "react";
import { IPwa } from "interfaces/pwas";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import StatsTable from "components/StatsTable";
import StatsSelectComponent from "components/StatsSelect";
import RangeSelectComponent from "components/RangeSelect";
import { IPwaStatisticsItem } from "pages/DashboardPage";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DateRangePicker, SingleInputDateRangeField } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import CachedOutlinedIcon from "@mui/icons-material/CachedOutlined";

export const getToken = () => {
  if (typeof window !== "undefined") {
    return localStorage.getItem("authToken") || "";
  }
};

export default function Stats() {
  const { t } = useTranslation();
  const location = useLocation();
  const [pwas, setPwas] = useState<Array<IPwa>>([]);
  const [pwasArray, setPwasArray] = useState<Array<string>>([]);
  const [clicks, setClicks] = useState<Array<IPwa>>([]);
  const [allClicks, setAllClicks] = useState<any>(null);
  const [totalInstallCount, setTotalInstallCount] = useState<number | undefined>(undefined);
  const [report, setReport] = useState<any>(null);
  const [selectedRange, setSelectedRange] = useState<string>("today");
  const token = getToken();
  const [value, setValue] = useState<[Dayjs | null, Dayjs | null]>([null, null]);
  const [customDate, setCustomDate] = useState<boolean>(false);

  const handleDateChange = (newValue: [Dayjs | null, Dayjs | null]) => {
    setValue(newValue);
  };

  const fromDate = value[0];
  const toDate = value[1];

  const { triggerFetch } = useFetch({
    url: "pwa/user",
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const { loading: loadingAllStats, triggerFetch: getAllStats } = useFetch({
    url: "tracking/keitaro-proxy",
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: {
      method: "POST",
      uri: "/report/build",
      requestBody: {
        range: {
          from: fromDate ? fromDate.format("YYYY-MM-DD") : "",
          to: toDate ? toDate.format("YYYY-MM-DD") : "",
          timezone: "Europe/Madrid",
          interval: selectedRange,
        },
        filters: [
          {
            name: "campaign",
            operator: "IN_LIST",
            expression: pwasArray,
          },
        ],
      },
    },
  });

  const { loading: loadingReport, triggerFetch: getReport } = useFetch({
    url: "tracking/keitaro-proxy",
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: {
      method: "POST",
      uri: "/report/build",
      requestBody: {
        range: {
          from: fromDate ? fromDate.format("YYYY-MM-DD") : "",
          to: toDate ? toDate.format("YYYY-MM-DD") : "",
          timezone: "Europe/Madrid",
          interval: selectedRange,
        },
        filters: [
          {
            name: "campaign",
            operator: "EQUALS",
            expression: report,
          },
        ],
      },
    },
  });

  useEffect(() => {
    void getPwas();
  }, []);

  const { triggerFetch: getStats } = useFetch({
    url: "statistics/",
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  useEffect(() => {
    const locationState = location.state;

    if (locationState) {
      setReport(location.state);
    } else {
      getPwas();
    }
  }, [location]);

  useEffect(() => {
    if (report) {
      void buildReport();
    }
  }, [report, selectedRange, fromDate, toDate]);

  useEffect(() => {
    if (Array.isArray(pwas) && pwas.length > 0) {
      const campaignNames = pwas.map((pwa) => pwa.appName).filter(Boolean);
      setPwasArray(campaignNames);
    }
  }, [pwas]);

  useEffect(() => {
    if (pwasArray.length > 0) {
      buildAllStats();
    }
  }, [pwasArray, selectedRange, fromDate, toDate]);

  console.log("res", clicks);

  async function getPwas() {
    const result = await triggerFetch();
    setPwas(result?.data?.items || []);
  }

  async function buildAllStats() {
    const res = await getAllStats();
    if (res?.data?.rows) {
      setAllClicks(res.data.rows);
    }

    const statsRes = await getStats();
    const totalInstallCount = statsRes?.data.items.reduce(
      (sum: number, app: IPwaStatisticsItem) => sum + app.installCount,
      0
    );
    setTotalInstallCount(totalInstallCount);
  }

  const handleSelectChange = (event: any) => {
    const value = event.target.value;
    setReport(value);
  };

  const handleRangeChange = (event: SelectChangeEvent<string>) => {
    setSelectedRange(event.target.value);
    if (event.target.value !== "custom") {
      setValue([null, null]);
    } else {
      setCustomDate(true);
    }
  };

  const buildReport = async () => {
    const res = await getReport();
    setClicks(res?.data?.rows || []);

    const statsRes = await getStats();
    const totalInstallCount = statsRes?.data.items.reduce(
      (sum: number, app: IPwaStatisticsItem) => sum + app.installCount,
      0
    );
    setTotalInstallCount(totalInstallCount);
  };

  const rangeOptions = [
    { value: "today", label: "Today" },
    { value: "yesterday", label: "Yesterday" },
    { value: "7_days_ago", label: "Last 7 Days" },
    { value: "first_day_of_this_week", label: "First day of this week" },
    { value: "1_month_ago", label: "1 month ago" },
    { value: "first_day_of_this_month", label: "First day of this month" },
    { value: "1_year_ago", label: "1 year ago" },
    { value: "first_day_of_this_year", label: "First day of this year" },
    { value: "all_time", label: "All time" },
    { value: "custom", label: "Custom" },
  ];

  const handleResetRange = () => {
    setValue([null, null]);
    setSelectedRange("today");
    buildAllStats();
  };

  const isLoading = loadingAllStats || loadingReport;

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          lg={12}
          sx={{
            maxWidth: "1180px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ fontSize: "24px", fontWeight: 500, display: "flex" }}>
            <StatsSelectComponent
              title={t("selectPwa")}
              options={pwas}
              backgroundColor="#F6F7F9"
              noBorder={true}
              value={pwas}
              onChange={handleSelectChange}
            />
            <RangeSelectComponent options={rangeOptions} value={selectedRange} onChange={handleRangeChange} />
            {customDate ? (
              <Box sx={{ display: "flex", justifyContent: "center", alignItems: "end", marginLeft: "15px" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateRangePicker
                    slots={{ field: SingleInputDateRangeField }}
                    name="allowedRange"
                    value={value}
                    onChange={handleDateChange}
                    label="Custom date"
                  />
                </LocalizationProvider>
                <Box sx={{ cursor: "pointer", marginLeft: "15px", marginBottom: "15px" }} onClick={handleResetRange}>
                  <CachedOutlinedIcon />
                </Box>
              </Box>
            ) : null}
          </Box>

          <Box>
            <Button
              sx={{
                backgroundColor: "#F4701B",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "#F0670B",
                },
                color: "white",
              }}
              className="create_btn"
              variant="contained"
              aria-label="create pwa"
              size="medium"
              onClick={buildReport}
            >
              {t("generateReport")}
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} lg={12}>
          <StatsTable
            getPwas={getPwas}
            loading={isLoading}
            pwas={pwas}
            clicks={clicks}
            totalInstallCount={totalInstallCount}
            allClicks={allClicks}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
