import React, { ChangeEvent, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import CustomInput from "components/Input";
import { useTranslation } from "react-i18next";
import CustomSelectComponent from "components/Select";
import { ILang } from "./Description";
import { useAppStore } from "store/appStore";
import CustomButton from "components/Button";
import { Typography } from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import useFetch from "hooks/useFetch";
import { IComment } from "interfaces/mockupApp";
import { translateText } from "utils/translationService";
import { getToken } from "utils/getToken";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "20px",
  bgcolor: "background.paper",
  boxShadow: 24,
  padding: "20px",
  width: "90%",
  maxWidth: "600px",
  maxHeight: "80vh",
};

interface IGenerateCommentModal {
  open: boolean;
  setOpen: (value: boolean) => void;
  languages: ILang[];
}

export default function GenerateCommentModal({ open, setOpen, languages }: IGenerateCommentModal) {
  const { appData, saveAppData } = useAppStore();

  const [formData, setFormData] = useState({
    numOfComments: "",
    lang: appData.language,
    ton: "",
  });
  const token = getToken();
  const { t } = useTranslation();
  const handleClose = () => setOpen(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSelectChange = (name: string) => (event: SelectChangeEvent<unknown>, child: React.ReactNode) => {
    const value = event.target.value as string;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const { data, error, loading, triggerFetch } = useFetch({
    url: "pwa/random-comments",
    method: "POST",
    body: {
      num: Number(formData.numOfComments),
      lang: formData.lang,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const handleGenerateComment = async () => {
    const result = await triggerFetch();
    if (result) {
      const formattedComments = result.data.map((comment: IComment) => ({
        author: comment.author,
        comment: comment.comment,
        rating: comment.rating,
        date: comment.date,
        avatar: comment.avatar,
      }));

      const translatedComments = await Promise.all(
        formattedComments.map(async (comment: IComment) => {
          const translatedText = await translateText({ text: comment.comment }, formData.lang);
          return {
            ...comment,
            comment: translatedText.text,
          };
        })
      );

      saveAppData({ comments: [...appData.comments, ...translatedComments] });
    }
    handleClose();
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-check-domain-title"
        aria-describedby="modal-check-domain-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingBottom: "24px",
            }}
          >
            <Typography
              id="modal-modal-title"
              sx={{
                textAlign: "left",
                fontSize: "24px",
                lineHeight: "45px",
                fontFamily: "var(--cygreMedium), sans-serif",
                color: "#2E263D",
              }}
            >
              {t("сommentgeneration")}
            </Typography>
            <Box aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </Box>
          </Box>
          <CustomInput
            margin="normal"
            required
            fullWidth
            id="numOfComments"
            placeholder={t("specifythenumberofcomments")}
            name="numOfComments"
            label={t("numberofcomments")}
            autoFocus
            value={formData.numOfComments}
            onChange={handleInputChange}
            customBgColor="#F6F7F9"
          />

          <Box
            sx={{
              paddingTop: "24px",
            }}
          >
            <CustomSelectComponent
              options={
                languages &&
                languages.map((lang: ILang) => ({
                  value: lang.code,
                  label: lang.name,
                }))
              }
              placeholder={t("selectlanguage")}
              title={t("language")}
              value={formData.lang}
              noBorder={true}
              backgroundColor="#F6F7F9"
              onChange={handleSelectChange("lang")}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              marginTop: "20px",
              justifyContent: "space-between",
            }}
          >
            <CustomButton
              textButton={t("cancel")}
              height={40}
              width={"100%"}
              customColor="#6D6777"
              customBorderColor="#C9D0D8"
              customHoverColor="transparent"
              customBorderRadius="8px"
              customFontSize="14px"
              onClick={handleClose}
            />
            <Box
              sx={{
                width: "20px",
              }}
            ></Box>
            <CustomButton
              textButton={t("generate")}
              height={40}
              width={"100%"}
              customColor="#fff"
              customBgColor="#F4701B"
              customBorderColor="#F4701B"
              customHoverColor="#F4701B"
              customBorderRadius="8px"
              customFontSize="14px"
              loading={loading}
              onClick={handleGenerateComment}
            />
          </Box>
        </Box>
      </Modal>
    </>
  );
}
