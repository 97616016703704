import React, { useState, useEffect } from "react";
import { Box, TextField, IconButton } from "@mui/material";
import ImageIcon from "assets/icons/ImageIcon";
import CancelIcon from "@mui/icons-material/Cancel";
import { useTranslation } from "react-i18next";
import { SharePopup, useSharePopup } from "components/SharePopup";
import { useAppStore, useErrorAppStore } from "store/appStore";
import showPreviewIcon from "utils/showPreviewImage";
import { translateText } from "utils/translationService";
import ImportPwa from "assets/icons/ImportPwa";
import Template from "assets/icons/Template";
import PlayIcon from "assets/icons/PlayIcon";
import StopIcon from "assets/icons/StopIcon";
import useFetch from "hooks/useFetch";
import useIdStore from "store/pwaId";
import CustomButton from "components/Button";
import useFetchFormData from "hooks/useFetchFormData";
import { getLocalStorageData, getUpdatedFields } from "utils/pwaHelpers";
import CautionModal from "components/CautionModal";
import useCautionStore from "store/cautionStore";
import useSetupStore from "store/setupSettingsStore";
import { useNavigate, useParams } from "react-router";
import { getToken } from "utils/getToken";
import ToastMessage from "components/ToastMessage";

import { BootstrapTooltip } from "./CommentsTable";

import { toast } from "react-toastify";
import { generateId } from "../trackingTab/TrackingTab";

export const buttonsStyles = {
  width: 44,
  height: 44,
  borderRadius: "8px",
  background: "#fff",
  border: "1px solid #C9D0D8",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
};

export default function NameApp() {
  const { appData, saveAppData, clearAppData } = useAppStore();
  const { errors, clearError } = useErrorAppStore();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [image, setImage] = useState<string | File | null>(null);
  const [appName, setAppName] = useState<string>(appData.appName || "");
  const [showDeleteIcon, setShowDeleteIcon] = useState(false);
  const { id: paramsID } = useParams();
  const { id = paramsID, setId, clearId } = useIdStore();
  const { isOpen, closeModal } = useCautionStore();
  const setSettingProcess = useSetupStore((state) => state.setSettingProcess);
  const resetSteps = useSetupStore((state) => state.resetAllSteps);
  const session = getToken();

  useEffect(() => {
    paramsID && setId(paramsID);
  }, [paramsID]);

  const { triggerFetch: createPwa } = useFetchFormData({
    url: "pwa",
    method: "POST",
    headers: {
      Authorization: `Bearer ${session}`,
    },
  });

  const { triggerFetch: getDomains } = useFetch({
    url: "tracking/keitaro-proxy",
    method: "POST",
    headers: {
      Authorization: `Bearer ${session}`,
    },
    body: {
      method: "GET",
      uri: "/domains",
    },
  });

  const handleSaveLink = () => {
    saveAppData({
      offerLink: appData.offerLink,
    });
  };

  const { triggerFetch: createCampaign } = useFetch({
    url: "tracking/keitaro-proxy",
    method: "POST",
    headers: {
      Authorization: `Bearer ${session}`,
      "Content-Type": "application/json",
    },
    body: {
      method: "POST",
      uri: "/campaigns",
      requestBody: {},
    },
  });

  const { triggerFetch: createFlow } = useFetch({
    url: "tracking/keitaro-proxy",
    method: "POST",
    headers: {
      Authorization: `Bearer ${session}`,
      "Content-Type": "application/json",
    },
    body: {
      method: "POST",
      uri: "/streams",
    },
  });

  const postCampaign = async (domainId: number, id: string, icon: string) => {
    try {
      const campaignRes = await createCampaign({
        requestBody: {
          alias: generateId(),
          name: appData.appName,
          domain_id: domainId,
        },
      });
      const campaignId = campaignRes.data.id;

      if (campaignId) {
        try {
          await createFlow({
            requestBody: {
              type: "regular",
              name: "Black",
              position: 1,
              weight: 100,
              state: "active",
              action_type: "http",
              schema: "redirect",
              collect_clicks: true,
              filter_or: false,
              action_payload: appData.offerLink,
              campaign_id: campaignId,
            },
          });
        } catch (error) {
          console.error("Error creating flow:", error);
        }
      }

      saveAppData({
        blackLink: `${campaignRes.data.domain}${campaignRes.data.alias}`,
      });

      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}pwa/${id}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${session}`,
          },
          body: JSON.stringify({
            blackLink: `${campaignRes.data.domain}${campaignRes.data.alias}`,
          }),
        });
        if (!response.ok) {
          throw new Error(`Ошибка: ${response.status} ${response.statusText}`);
        }
        const result = await response.json();
        return result;
      } catch (err: unknown) {
        if (err instanceof Error) {
          console.log("Ошибка:", err.message);
        } else {
          console.log("Неизвестная ошибка:", err);
        }
      }
    } catch (error) {
      console.error("Error creating campaign:", error);
    }
  };

  const { triggerFetch: updatePWA } = useFetchFormData({
    url: `pwa/${paramsID}`,
    method: "PUT",
    headers: {
      Authorization: `Bearer ${session}`,
    },
  });

  const { triggerFetch: attachDomain } = useFetch({
    url: "",
    method: "POST",
    headers: {
      Authorization: `Bearer ${session}`,
    },
  });

  async function changePwaDomain(domainID: string, pwaid: string) {
    try {
      const result = await attachDomain(
        {
          domain: domainID,
        },
        `pwa/${pwaid}/domain`
      );

      if (!result.success) {
        toast.error(result?.message || "Attach domain failed");
        return;
      }
      toast.success("Domain attached successfully");
      await getDomains();
      return result.data;
    } catch (error: any) {
      toast.error("Error", error?.message || "Attach domain failed");
    }
  }

  const handlePwa = async () => {
    const data = new FormData();

    const appendField = (field: string, value: any) => {
      if (Array.isArray(value) || typeof value === "object") {
        data.append(field, JSON.stringify(value));
      } else {
        data.append(field, value as string | Blob);
      }
    };

    if (!id) {
      Object.entries(appData).forEach(([field, value]) => {
        if (field === "creatives") {
          const creatives = value as typeof appData.creatives;
          if (creatives?.icon) {
            data.append("icon", creatives.icon);
          }
          if (creatives?.previews?.length) {
            creatives.previews.forEach((preview) => {
              data.append("previews", preview);
            });
          }
        } else {
          appendField(field, value);
        }
      });

      const res = await createPwa(data);
      if (res?.data) {
        localStorage.setItem("pwaData", JSON.stringify(res.data));
        saveAppData(res.data);
        setId(res.data._id);

        const storageDomain = localStorage.getItem("tmpDomainID");

        if (storageDomain) {
          await changePwaDomain(storageDomain, res.data._id);
          localStorage.removeItem("tmpDomainID");
        }

        const domainsResponse = await getDomains();
        const domainsData = domainsResponse.data;

        const domainToAdd = domainsData[domainsData.length - 1];

        await postCampaign(domainToAdd.id, res.data._id, res.data.creatives.icon);
        handleSaveLink();
        toast.success(t("successPwaCreate"));
      }
    } else {
      const updatedFields = getUpdatedFields();

      if (updatedFields) {
        Object.entries(updatedFields).forEach(([field, value]) => {
          if (field === "creatives") {
            const creatives = value as typeof appData.creatives;
            if (creatives?.icon) {
              data.append("icon", creatives.icon);
            }
            if (creatives?.previews?.length) {
              creatives.previews.forEach((preview) => {
                data.append("previews", preview);
              });
            }
          } else if (field === "ratings" || field === "comments") {
            appendField(field, value);
          } else {
            appendField(field, value);
          }
        });
      }

      if (id) {
        const res = await updatePWA(data);
        if (res?.data) {
          console.log("123");
          saveAppData(res.data);
          localStorage.setItem("pwaData", JSON.stringify(res.data));
          toast.success(t("successPwaUpdate"));
        }
      }
    }
  };

  const handleConfirmSavePwa = async () => {
    const data = new FormData();

    const appendField = (field: string, value: any) => {
      if (Array.isArray(value) || typeof value === "object") {
        data.append(field, JSON.stringify(value));
      } else {
        data.append(field, value as string | Blob);
      }
    };

    const updatedFields = getUpdatedFields();

    if (updatedFields && paramsID) {
      Object.entries(updatedFields).forEach(([field, value]) => {
        if (field === "creatives") {
          const creatives = value as typeof appData.creatives;
          if (creatives?.icon) {
            data.append("icon", creatives.icon);
          }
          if (creatives?.previews?.length) {
            creatives.previews.forEach((preview) => {
              data.append("previews", preview);
            });
          }
        } else if (field === "ratings" || field === "comments") {
          appendField(field, value);
        } else {
          appendField(field, value);
        }
      });
    }

    const res = await updatePWA(data);

    if (res.success) {
      clearAppData();
      setSettingProcess(false);
      resetSteps();
      clearId();
      closeModal();
      localStorage.removeItem("pwaData");
      navigate(-1);
    }

    if (!paramsID && updatedFields) {
      Object.entries(appData).forEach(([field, value]) => {
        if (field === "creatives") {
          const creatives = value as typeof appData.creatives;
          if (creatives?.icon) {
            data.append("icon", creatives.icon);
          }
          if (creatives?.previews?.length) {
            creatives.previews.forEach((preview) => {
              data.append("previews", preview);
            });
          }
        } else {
          appendField(field, value);
        }
      });

      const res = await createPwa(data);
      if (res?.data) {
        clearAppData();
        setSettingProcess(false);
        resetSteps();
        clearId();
        closeModal();
        navigate(-1);
      }
    }
  };

  const { openPopup, closePopup, shareLink, copied, setCopied, handleCopy, handleOpenPopup } = useSharePopup();
  const { triggerFetch: triggerShare } = useFetch({
    url: `pwa/${paramsID}/share-pwa`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${session}`,
    },
  });

  const handleShare = async () => {
    if (id) {
      try {
        const shareLink = await triggerShare();
        handleOpenPopup(window.location.host + shareLink?.data?.shareLink);
      } catch (error) {
        console.error("Ошибка при получении ссылки для шаринга:", error);
      }
    }
  };

  useEffect(() => {
    const trnslateName = async () => {
      const translatedAppName = await translateText({ appName: appName }, appData.language);

      setAppName(translatedAppName.appName);
      saveAppData({ appName: translatedAppName.appName });
    };

    if (appData.language.length) {
      trnslateName();
    }
  }, [appData.language]);

  useEffect(() => {
    if (appData.creatives?.icon) {
      setImage(appData.creatives.icon);
    }
  }, [appData.creatives?.icon]);

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      if (file.type.startsWith("image/")) {
        setImage(file);
        saveAppData({ creatives: { ...appData.creatives, icon: file } });
      } else {
        alert("Select image!");
      }
    }
  };

  const handleAppNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAppName(e.target.value);
    saveAppData({ appName: e.target.value });
    if (e.target.value) {
      clearError("appName");
    }
  };
  const token = getToken();
  const { triggerFetch: triggerCopyPwa } = useFetch({
    url: `pwa/copy/${paramsID}`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  async function handleCopyPwa() {
    const res = await triggerCopyPwa();

    if (res.success) {
      ToastMessage({ mode: "info", message: t("succescopy") });
      setTimeout(() => {
        navigate("/pwa");
      }, 3000);
    }
  }

  const handleImageRemove = () => {
    setImage(null);
    saveAppData({ creatives: { ...appData.creatives, icon: null } });
  };

  const handlePWAStatusChange = (status: "active" | "pause") => {
    if (!appData?._id) {
      ToastMessage({ mode: "error", message: t("PWAStatusChangeError") });
      return;
    }
    saveAppData({ status });
    const data = new FormData();
    data.append("status", status);
    void updatePWA(data);
    ToastMessage({ mode: "info", message: t(status === "active" ? "pwaActivated" : "pwaPaused") });
  };

  return (
    <>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", paddingBottom: "24px" }}>
          <Box
            sx={{
              position: "relative",
              "&:hover .delete-icon": {
                opacity: 1,
              },
            }}
            onMouseEnter={() => setShowDeleteIcon(true)}
            onMouseLeave={() => setShowDeleteIcon(false)}
          >
            {image ? (
              <>
                {showDeleteIcon && (
                  <IconButton
                    className="delete-icon"
                    sx={{
                      position: "absolute",
                      top: "-10px",
                      right: "-10px",
                      zIndex: 1,
                      color: "white",
                      opacity: 0,
                      transition: "opacity 0.3s ease",
                    }}
                    onClick={handleImageRemove}
                  >
                    <CancelIcon color="warning" fontSize="small" />
                  </IconButton>
                )}
                <img
                  src={showPreviewIcon(image)}
                  alt="select-image"
                  className="uploaded-image"
                  style={{
                    width: 88,
                    height: 88,
                    borderRadius: "16px",
                  }}
                />
              </>
            ) : (
              <label className="custom-input">
                <input
                  id="fileInput"
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                  style={{ display: "none" }}
                />
                <IconButton
                  sx={{
                    background: "#F6F7FB",
                    width: 88,
                    height: 88,
                    borderRadius: "16px",
                    display: "flex",
                    alignItems: "center",
                    justifyItems: "center",
                  }}
                  component="span"
                >
                  {appData.creatives.icon ? (
                    <img
                      src={showPreviewIcon(appData.creatives.icon)}
                      alt="selected-image"
                      style={{
                        width: 88,
                        height: 88,
                        borderRadius: "16px",
                      }}
                    />
                  ) : (
                    <ImageIcon />
                  )}
                </IconButton>
              </label>
            )}
          </Box>
          <Box>
            <TextField
              required
              sx={{
                input: {
                  fontFamily: "CygreThin",
                  fontSize: "24px",
                  "&::placeholder": {
                    textOverflow: "ellipsis !important",
                    color: "black",
                    fontFamily: "var(--cygreRegular), sans-serif",
                    fontSize: "24px",
                  },
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
              }}
              fullWidth
              error={!!errors.appName}
              helperText={errors.appName}
              placeholder={t("nameApp")}
              value={appName}
              onChange={handleAppNameChange}
            />
          </Box>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", width: "150px" }}>
          <Box sx={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
            <BootstrapTooltip title={t("sharepwa")} placement="top">
              <Box component="button" onClick={handleShare} sx={{ ...buttonsStyles }}>
                <ImportPwa color={""} />
              </Box>
            </BootstrapTooltip>
            <BootstrapTooltip title={t("copy")} placement="top">
              <Box onClick={handleCopyPwa} component="button" sx={{ ...buttonsStyles }}>
                <Template color={""} />
              </Box>
            </BootstrapTooltip>
            {appData.status === "pause" && (
              <BootstrapTooltip title={t("setactive")} placement="top">
                <Box component="button" onClick={() => handlePWAStatusChange("active")} sx={{ ...buttonsStyles }}>
                  <PlayIcon />
                </Box>
              </BootstrapTooltip>
            )}
            {appData.status === "active" && (
              <BootstrapTooltip title={t("setstop")} placement="top">
                <Box onClick={() => handlePWAStatusChange("pause")} component="button" sx={{ ...buttonsStyles }}>
                  <StopIcon />
                </Box>
              </BootstrapTooltip>
            )}
          </Box>
          <Box
            sx={{
              marginTop: "16px",
            }}
          >
            <CustomButton
              textButton={t("save")}
              height={40}
              width={"100%"}
              customBgColor="#F4701B"
              customHoverColor="#F4701B"
              customBorderRadius="8px"
              customFontSize="12px"
              onClick={handlePwa}
            />
          </Box>
        </Box>
      </Box>
      <SharePopup
        open={openPopup}
        onClose={closePopup}
        shareLink={shareLink}
        copied={copied}
        setCopied={setCopied}
        handleCopy={handleCopy}
      />
      <CautionModal openModal={isOpen} handleUpdatePwa={handleConfirmSavePwa} />
    </>
  );
}
