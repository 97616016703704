import React from "react";
import Lottie from "lottie-react";

import preloaderAnimation from "./preloaderLottie";
const Preloader: React.FC<any> = ({ customStyles }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        border: "1px solid #EEEEEE",
        borderRadius: "8px",
        padding: "20px 27px",
        width: "100%",
        margin: "24px 0",
        ...customStyles,
      }}
    >
      <Lottie
        animationData={preloaderAnimation}
        loop={true}
        style={{ display: "flex", justifyContent: "center", alignItems: "center", width: 70, height: 70 }}
      />
    </div>
  );
};

export default Preloader;
