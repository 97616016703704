import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import SearchBar from "components/SearchBar";
import { fetchCountries } from "utils/fetchCountries";
import FilterButton from "components/Filter/FilterButton";
import { IAppData, ICountry, INotification, IPushTableProps } from "interfaces/push";
import NotificationsList from "./NotificationsList";

const PushTable = ({ pushes, pwas, getNot }: IPushTableProps) => {
  const [dataPushes, setDataPushes] = useState<any>([]);
  const [filteredPushes, setFilteredPushes] = useState<any>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [countries, setCountries] = useState<any>([]); // Исправил инициализацию стран
  const { t } = useTranslation();

  useEffect(() => {
    setDataPushes(pushes);
    setFilteredPushes(pushes); // Обновление фильтрованных пушей вместе с основными
  }, [pushes]);

  useEffect(() => {
    const getCountries = async () => {
      const data = await fetchCountries();
      const formattedOptions = data.map((country: ICountry) => ({
        country: country.name,
        flag: country.flag,
      }));
      setCountries(formattedOptions);
    };
    getCountries();
  }, []);

  useEffect(() => {
    if (searchQuery === "") {
      setFilteredPushes(dataPushes); // Обновление после очистки строки поиска
    } else {
      const filtered = dataPushes.map((item: { notifications: INotification[]; appData: IAppData }) => ({
        ...item,
        notifications: item?.notifications?.filter((notification: INotification) =>
          notification?.name?.toLowerCase().includes(searchQuery.toLowerCase())
        ),
      }));
      setFilteredPushes(filtered);
    }
  }, [searchQuery, dataPushes]); // Добавил зависимость от dataPushes

  const handleSearch = (query: string) => {
    setSearchQuery(query);
  };

  const displayPushes = filteredPushes.filter(
    (item: { notifications: INotification[]; appData: IAppData }) => item.notifications && item.notifications.length > 0
  );

  return (
    <Box>
      <Box sx={{ width: { sm: "auto" } }}>
        <Grid
          item
          xs={12}
          lg={12}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              alignItems: "normal",
              width: "100%",
              marginBottom: "16px",
              flexDirection: { xs: "column", sm: "row" },
            }}
          >
            <SearchBar onSearch={handleSearch} placeholder={t("searchname")} />
            <FilterButton menuData={[]} filter={{ status: [] }} handleApply={() => {}} />
          </Box>
        </Grid>
        <Grid container spacing={2} rowSpacing={3} sx={{ marginTop: 0 }}>
          {displayPushes.length > 0 ? (
            displayPushes.map((item: { notifications: INotification[]; appData: IAppData }, index: number) => (
              <NotificationsList
                key={index}
                getNot={getNot}
                notifications={item.notifications}
                countries={countries}
                appData={item.appData}
                pwas={pwas}
              />
            ))
          ) : (
            <Grid item xs={12}>
              <Typography variant="subtitle2" fontWeight={600}>
                {t("noitems")}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  );
};

export default PushTable;
